import React, {useEffect, useRef} from 'react';
import Confetti from 'react-confetti';
import {ThemeProvider} from 'styled-components';
import {theme} from '../../styles/theme';
// Components
import Button from '../Button';
import IconButton from '../IconButton';
// Images
import CloseIcon from '../../images/close-icon.svg';
import ChevronLeftIcon from '../../images/chevron-left-icon.svg';
// Style
import S from './style';

interface Buttons {
  button1Text: string;
  button1OnClick?: Function;
  button2Text: string;
  button2OnClick: Function;
  button2Disabled?: boolean;
  button2Loading?: boolean;
}

export interface ModalProps {
  closeModal?: Function;
  mobileHeight?: string;
  desktopHeight?: string;
  handleBack?: Function;
  backVisible?: boolean;
  confetti?: boolean;
  padding?: boolean;
  mobileCentered?: boolean;
  submitDisabled?: boolean;
  buttons?: Buttons | false;
  overflowMobile?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Modal = ({
  closeModal,
  mobileHeight = '',
  desktopHeight = '',
  handleBack,
  backVisible = false,
  confetti = false,
  mobileCentered = false,
  buttons = false,
  overflowMobile = false,
  children,
}: ModalProps) => {
  // Selectors
  const modalWrapperInnerRef = useRef<HTMLDivElement>(null);

  // lock page when modal open
  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    if (body) {
      body.style.overflow = 'hidden';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
      }
    };
  }, []);

  const getConfettiWidth = () => {
    if (modalWrapperInnerRef.current) {
      return modalWrapperInnerRef.current.clientWidth;
    }
    return 0;
  };

  const getConfettiHeight = () => {
    if (modalWrapperInnerRef.current) {
      return modalWrapperInnerRef.current.clientHeight;
    }
    return 0;
  };

  const handleModalClose = () => {
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <S.Modal mobileCentered={mobileCentered}>
        <S.BackgroundOverlay onClick={handleModalClose} />
        <S.ModalWrapper
          mobileHeight={mobileHeight}
          desktopHeight={desktopHeight}
          mobileCentered={mobileCentered}
        >
          <S.ModalWrapperInner ref={modalWrapperInnerRef}>
            <S.TopWrapper>
              {backVisible && handleBack ? (
                <IconButton
                  icon={ChevronLeftIcon}
                  onClick={handleBack}
                  size="medium"
                  type="tertiary"
                />
              ) : (
                <S.IconPlaceholder />
              )}
              {closeModal ? (
                <IconButton
                  icon={CloseIcon}
                  onClick={closeModal}
                  size="medium"
                  type="tertiary"
                />
              ) : (
                <S.IconPlaceholder />
              )}
            </S.TopWrapper>
            {confetti && (
              <S.ConfettiWrapper>
                <Confetti
                  height={getConfettiHeight()}
                  width={getConfettiWidth()}
                  numberOfPieces={110}
                  colors={[
                    '#d6f3f7',
                    '#ddf1e2',
                    '#fddfe8',
                    '#eedcf0',
                    '#dfe2f1',
                    '#fff7e6',
                  ]}
                  gravity={0.07}
                />
              </S.ConfettiWrapper>
            )}
            <S.ContentWrapper overflowMobile={overflowMobile}>
              {children}
            </S.ContentWrapper>
            {buttons && (
              <S.Buttons>
                <S.CancelButton>
                  <Button
                    type="tertiary"
                    size="medium"
                    v3={true}
                    text={buttons.button1Text}
                    onClick={
                      buttons.button1OnClick
                        ? buttons.button1OnClick
                        : closeModal
                    }
                  />
                </S.CancelButton>
                <Button
                  type="primary"
                  size="medium"
                  v3={true}
                  text={buttons.button2Text || 'Submit'}
                  disabled={buttons.button2Disabled}
                  onClick={buttons.button2OnClick}
                  loading={buttons.button2Loading}
                />
              </S.Buttons>
            )}
          </S.ModalWrapperInner>
        </S.ModalWrapper>
      </S.Modal>
    </ThemeProvider>
  );
};

export default React.memo(Modal);
