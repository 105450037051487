import styled, {DefaultTheme} from 'styled-components';
import {focus, hover, active} from '../../styles/pseudoClasses';

import {Sizes, ButtonTypes} from './index';

const S: Record<string, React.ElementType> = {};

const size = {
  xsmall: '14px',
  small: '32px',
  medium: '48px',
};

const colors = {
  background: {
    disabled: {
      primary: 'backgroundDisabled',
      'primary inverse': 'backgroundInverseDisabled',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
    },
    default: {
      primary: 'backgroundInversePrimary',
      'primary inverse': 'backgroundPrimary',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
    },
  },
  border: {
    disabled: {
      primary: 'backgroundDisabled',
      'primary inverse': 'backgroundInverseDisabled',
      secondary: 'borderSubdued',
      'secondary inverse': 'borderInverseSubdued',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
    },
    default: {
      primary: 'backgroundInversePrimary',
      'primary inverse': 'backgroundPrimary',
      secondary: 'borderPrimary',
      'secondary inverse': 'borderInversePrimary',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
    },
  },
  overlay: {
    hover: {
      primary: 'actionInverseHover',
      'primary inverse': 'actionHover',
      secondary: 'actionHover',
      'secondary inverse': 'actionInverseHover',
      tertiary: 'actionHover',
      'tertiary inverse': 'actionInverseHover',
    },
    pressed: {
      primary: 'actionInversePressed',
      'primary inverse': 'actionPressed',
      secondary: 'actionPressed',
      'secondary inverse': 'actionInversePressed',
      tertiary: 'actionPressed',
      'tertiary inverse': 'actionInversePressed',
    },
  },
  focus: {
    primary: 'focus',
    'primary inverse': 'focusInverse',
    secondary: 'focus',
    'secondary inverse': 'focusInverse',
    tertiary: 'focus',
    'tertiary inverse': 'focusInverse',
    lightning: 'focusInverse',
    'lightning secondary': 'focusInverse',
  },
};

const getColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  position: string
) => {
  if (disabled) {
    if (position === 'border') {
      return theme.borderColor[colors[position].disabled[type]];
    }
    if (position === 'background') {
      return theme.backgroundColor[colors[position].disabled[type]];
    }
  } else {
    if (position === 'border') {
      return theme.borderColor[colors[position].default[type]];
    }
    if (position === 'background') {
      return theme.backgroundColor[colors[position].default[type]];
    }
  }
};

const getBorder = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes
) => {
  const borderColor = getColor(theme, disabled, type, 'border');
  if (borderColor) {
    return `${theme.borderWidth.default} solid ${borderColor}`;
  }
  return 'none';
};

S.ButtonWrapper = styled.div(
  (props: {
    $size: Sizes;
    $type: ButtonTypes;
    disabled: boolean;
    selected: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  height: ${size[props.$size]};
  width: ${size[props.$size]};
  cursor: ${props.disabled ? 'auto' : 'pointer'};
  ${props.selected && `border-bottom: 4px solid ${props.theme.color.white}`}

  ${focus(props.theme.focusColor[colors.focus[props.$type]], props.disabled)}
  ${hover(
    props.theme.backgroundColor[colors.overlay.hover[props.$type]],
    props.disabled
  )}
  ${active(
    props.theme.backgroundColor[colors.overlay.pressed[props.$type]],
    props.disabled
  )}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $type: ButtonTypes;
    disabled: boolean;
    $size: Sizes;
  }) => `
  height: ${size[props.$size]};
  width: ${size[props.$size]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'background'
  )};
  border: ${getBorder(props.theme, props.disabled, props.$type)};
  position: relative;
`
);

S.Icon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
  position: relative;
`;

export default S;
