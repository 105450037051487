import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import Link from 'next/link';
// Style
import S from './style';

const SampleIcon = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M19.8991 8.13614C19.9112 8.31114 19.9112 8.48614 19.9112 8.66275C19.9112 14.0442 15.8144 20.2507 8.32331 20.2507V20.2474C6.11041 20.2507 3.94347 19.6168 2.08057 18.4216C2.40234 18.4603 2.72573 18.4797 3.04992 18.4805C4.88379 18.4821 6.66524 17.8668 8.10799 16.7337C6.36524 16.7007 4.83702 15.5644 4.30315 13.9055C4.91363 14.0232 5.54266 13.999 6.14186 13.8353C4.24186 13.4515 2.87492 11.7821 2.87492 9.8434C2.87492 9.82565 2.87492 9.80872 2.87492 9.79178C3.44105 10.1071 4.07492 10.2821 4.72331 10.3015C2.93379 9.10549 2.38218 6.72485 3.46282 4.86356C5.53057 7.40791 8.58137 8.95469 11.8564 9.1184C11.5281 7.70388 11.9765 6.22162 13.0346 5.22727C14.6749 3.68533 17.2548 3.76437 18.7967 5.40388C19.7088 5.22404 20.583 4.88937 21.383 4.41517C21.079 5.35791 20.4427 6.15872 19.5927 6.66759C20.3999 6.57243 21.1886 6.3563 21.9314 6.02646C21.3846 6.84582 20.6959 7.55953 19.8991 8.13614Z"
        fill={color}
      />
    </svg>
  );
};

export type Sizes = 'xsmall' | 'small' | 'medium';
export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'primary inverse'
  | 'secondary inverse'
  | 'tertiary inverse';

export interface ButtonProps {
  type: ButtonTypes;
  disabled?: boolean;
  onClick?: Function;
  icon: string;
  size: Sizes;
  href?: string;
  theme: DefaultTheme;
  selected?: boolean;
}

const IconButton = ({
  type,
  disabled = false,
  onClick,
  icon,
  size,
  href,
  theme,
  selected = false,
}: ButtonProps) => {
  const iconColors = {
    default: {
      primary: 'textInverse',
      'primary inverse': 'textDefault',
      secondary: 'textDefault',
      'secondary inverse': 'textInverse',
      tertiary: 'textSubdued',
      'tertiary inverse': 'textWhite',
    },
    disabled: {
      primary: 'textDisabled',
      'primary inverse': 'textInverseDisabled',
      secondary: 'textDisabled',
      'secondary inverse': 'textInverseDisabled',
      tertiary: 'textDisabled',
      'tertiary inverse': 'textInverseDisabled',
    },
  };

  const handleClick = e => {
    e.stopPropagation();
    if (!onClick) {
      return null;
    }
    return onClick(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled && onClick) {
      return onClick(e);
    }
  };

  const getContent = () => {
    return (
      <S.Button $type={type} disabled={disabled} $size={size}>
        {icon ? (
          <S.Icon src={icon} />
        ) : (
          <SampleIcon
            color={
              disabled
                ? theme.textColor[iconColors.disabled[type]]
                : theme.textColor[iconColors.default[type]]
            }
          />
        )}
      </S.Button>
    );
  };

  // div with focus properties has to come immediately after <Link />
  if (href) {
    return (
      <Link href={href} passHref>
        <S.ButtonWrapper
          tabIndex={disabled ? -1 : 0}
          $type={type}
          size={size}
          as="a"
          disabled={disabled}
        >
          {getContent()}
        </S.ButtonWrapper>
      </Link>
    );
  }

  return (
    <S.ButtonWrapper
      tabIndex={disabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      $size={size}
      onClick={disabled ? null : handleClick}
      $type={type}
      disabled={disabled}
      selected={selected}
    >
      {getContent()}
    </S.ButtonWrapper>
  );
};

export default withTheme(IconButton);
